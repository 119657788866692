const trimZeroes = (number) => {
  if(parseFloat(number) > parseInt(number))
    return parseFloat(number).toFixed(2).replace('.', ',')
  else
    return parseInt(number);
}

export function openModal(attrs) {
  const { fio = "", short_job, gender = '', region = '', clusterLetter, betweenesscentrality, childs = [], vidy = null } = attrs;
  let modal = document.querySelector("#modal-info");

  // modal.querySelector(".recommends ol").innerHTML = "";

  modal.querySelector(".fio").innerText = fio;
  modal.querySelector(".job").innerText = short_job;
  modal.querySelector(".city").innerText = region;

  modal.querySelector(".cell.incoming .value").innerText = attrs['in-degree'] || 0;
  modal.querySelector(".cell.outgoing .value").innerText = attrs['out-degree'] || 0;
  modal.querySelector(".cell.betweenesscentrality .value").innerText = betweenesscentrality ? trimZeroes(betweenesscentrality) : 0;
  modal.querySelector(".modal-head .cluster .letter").innerText = clusterLetter || "?";


  console.log(attrs)

  if(vidy){
    modal.querySelector(".interaction").classList.remove('none');
    modal.querySelector(".interaction ol").innerHTML = '';

    let fields = vidy.split('<br>');
    fields.forEach(el => {
      let li = document.createElement('li');
      li.innerText = el;
      modal.querySelector(".interaction ol").appendChild(li)
    })
  }else{
    modal.querySelector(".interaction").classList.add('none');
  }

  // childs.forEach((el) => {
  //   let link = document.createElement("a");
  //   link.innerText = el.values.fio2;
  //   let li = document.createElement('li');
  //   li.appendChild(link);

  //   document.querySelector("#modal-info .recommends ol").appendChild(li);
  // });

  modal.classList.add(`cluster-${clusterLetter || 'default'}`);
  modal.classList.add("show");
}

export function closeModal() {
  return document.querySelector("#modal-info").className = '';
}
