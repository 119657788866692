import { openModal, closeModal } from "./modal";
import { hideNotBound, showAll, getOutgoingEdges } from "./utils";

// Filters
export const onSizeChange = (e) => {
  const mode = e.target.value;

  window.cy.$("node").removeClass("betweeness");
  window.cy.$("node").removeClass("in-degree");

  let desktopHint = document.querySelector("#filters i");

  switch (mode) {
    case "all-degree":
      if(desktopHint) desktopHint.innerText = "Чем больше кружок, тем больше связей имеет человек";
      break;

    case "incoming-degree":
      window.cy.$("node").addClass("in-degree");
      if(desktopHint) desktopHint.innerText = "Чем больше кружок, тем больше входящих связей имеет человек";
      break;

    case "betweenness":
      window.cy.$("node").addClass("betweeness");
      if(desktopHint) desktopHint.innerText = "Чем больше кружок, тем больше битвинность";
      break;
  }
};

export const onColorChange = (e) => {
  const mode = e.target.value;

  let circles = document.querySelectorAll(".colors .circle");

  const clearClasses = (node) => {
    node.classList.remove(...node.classList);
    node.classList.add("circle");
  };

  window.cy.$("node").removeClass("gender");
  window.cy.$("node").removeClass("region");
  window.cy.$("node").removeClass("cluster");
  window.cy.$("node").removeClass("work");

  switch (mode) {
    case "gender":
      const gender = ["Мужской", "Женский"];
      circles.forEach((el, i) => {
        el.innerText = gender[i];
        clearClasses(el);
        el.classList.add(`gender-${i < 2 ? i : "none"}`);
        el.setAttribute("data-circle-value", gender[i]);
      });

      // Меняем узлы
      window.cy.$("node").addClass("gender");
      // Перекрашиваем ребра
      window.cy.nodes().forEach((node) =>
        getOutgoingEdges(node).forEach((edge) =>
          edge.style({
            "line-color": node.data().genderColor,
            "target-arrow-color": node.data().genderColor,
          })
        )
      );

      break;

    case "cluster":
      const clusters = ["A", "B", "C", "D", "E", "F", "G", "H"];
      circles.forEach((el, i) => {
        el.innerText = `${clusters[i]} ${CLUSTER_PERCENT[clusters[i]]}`;
        clearClasses(el);
        el.classList.add(`cluster-${clusters[i]}`);
        el.setAttribute("data-circle-value", clusters[i]);
      });
      break;

    case "region":
      const cities = [
        "Москва",
        "Санкт-Петербург",
        "Казань",
        "Екатеринбург",
        "Самара",
        "Красноярск",
        "Барнаул",
        "Ижевск",
      ];
      circles.forEach((el, i) => {
        el.innerText = cities[i];
        clearClasses(el);
        el.classList.add(`region-${[i + 1]}`);
        el.setAttribute("data-circle-value", i + 1);
      });

      // Меняем узлы
      window.cy.$("node").addClass("region");
      // Перекрашиваем ребра
      window.cy
        .nodes()
        .forEach((node) =>
          getOutgoingEdges(node).forEach((edge) =>
            edge.style({
              "line-color": node.data().regionColor,
              "target-arrow-color": node.data().regionColor,
            })
          )
        );
        
      break;

    case "work":
      const workTypes = [
        "ЗОЖ",
        "Партнерство",
        "Потребление",
        "Правосудие и мир",
        "Города",
        "Образование",
        "Инновации",
        "Культура"
      ]

      circles.forEach((el, i) => {
        el.innerText = workTypes[i];
        clearClasses(el);
        el.classList.add(`work-${[i+1]}`);
        el.setAttribute("data-circle-value", i + 1);
      });

      // Меняем узлы
      window.cy.$("node").addClass("work");

      // Перекрашиваем ребра
      window.cy
        .nodes()
        .forEach((node) =>
          getOutgoingEdges(node).forEach((edge) =>
            edge.style({
              "line-color": node.data().workColor,
              "target-arrow-color": node.data().workColor,
            })
          )
        );

      break;
  }
};

export const onCircleMouseOver = (e) => {
  const circle = e.target;
  document
    .querySelectorAll(".colors .circle")
    .forEach((el) => el !== circle && el.classList.add("opacity"));

  const mode = document.querySelector("select[name='filter-color']").value;
  const value = circle.getAttribute("data-circle-value");

  window.cy.nodes().forEach((n) => {
    const data = n.data();

    switch (mode) {
      case "gender":
        const { gender } = data.values;
        if (gender && value.toLowerCase() !== gender.trim().toLowerCase())
          n.addClass("not-hover");
        break;

      case "cluster":
        const { clusterLetter: cluster } = data;
        if (cluster && value !== cluster) n.addClass("not-hover");
        break;

      case "region":
        const { regionNumber: region } = data;
        if (region && parseInt(value) !== parseInt(region))
          n.addClass("not-hover");
        break;

      case "work":
        const { workIndex: work } = data;
        if (work && parseInt(value) !== parseInt(work))
          n.addClass("not-hover");
          
        break;
    }
  });
};

export const onCircleMouseLeave = (e) => {
  document
    .querySelectorAll(".colors .circle")
    .forEach((el) => el.classList.remove("opacity"));
  window.cy.nodes().forEach((n) => n.removeClass("not-hover"));
};
///

export const onNodeClick = (config) => (event) => {
  window.INFO_OPENED = false;
  onNodeMouseOver(event);
  closeModal();
  window.INFO_OPENED = true;

  const elId = event.target.id();

  const elData = config.elements.nodes.find((el) => el.data.id === elId).data;
  const connectedEdges = event.target.connectedEdges();
  const childs = [];

  connectedEdges
    .targets()
    .filter((el) => el.id() !== elId)
    .forEach((el) => {
      childs.push(config.elements.nodes.find((n) => n.data.id === el.id()));
    });

  openModal({
    fio: elData.values.fio2,
    clusterLetter: elData.clusterLetter,
    childs: childs.map((el) => ({ ...el.data })),
    ...elData.values,
  });
};

export const onNodeMouseOver = (event) => {
  if(window.INFO_OPENED) return false;

  const cy = window.cy;
  const t = event.target;
  const connectedEdges = t.connectedEdges();
  
  hideNotBound(t);

  connectedEdges.forEach((edge) =>
    edge.style("target-arrow-shape", "triangle")
  );
};

export const onNodeMouseOut = (event) => {
  if(window.INFO_OPENED) return false;
  const cy = window.cy;
  showAll();

  event.target
    .connectedEdges()
    .forEach((edge) => edge.style("target-arrow-shape", ""));
};

export const onCanvasClick = (event) => {
  // const cy = window.cy;
  // if (event.target === cy) closeModal();
};

export const onSearch = () => {
  const cy = window.cy;
  const query = document
    .querySelector("input[name='search']")
    .value.trim()
    .toLowerCase();
  const reg = new RegExp(`^.*${query}.*$`, "g");

  document
    .querySelector("input[name='search']")
    .classList.remove("with-results");
  document.querySelector('input[name="search"] ~ .results').innerHTML = "";

  if (query.length >= 3) {
    const nodes = cy.nodes();
    const results = nodes.filter((node) => {
      const data = node.data();
      const fio = data.values.fio2;
      const job = data.values.short_job;

      const matchedFio = fio && fio.toLowerCase().search(reg) !== -1;
      const matchedJob = job && job.toLowerCase().search(reg) !== -1;

      return matchedFio || matchedJob;
    });

    if (results.length) {
      cy.$("node").addClass("not-hover");
      document
        .querySelector("input[name='search']")
        .classList.add("with-results");

      results.forEach((el) => {
        const { id, values } = el.data();
        const resultNode = cy.$(`node[id='${id}']`);
        resultNode.removeClass("not-hover");

        const { fio2: fio, short_job: job } = values;

        const tag = document.createElement("span");
        tag.innerHTML = `${fio} (${job})`;

        tag.addEventListener("click", function () {
          if (window.innerWidth > 600) {
            resultNode.trigger("mouseover");
            resultNode.trigger("click");
          }
          document
            .querySelector("input[name='search']")
            .classList.remove("with-results");
          document.querySelector("input[name='search']").value = "";

          cy.fit(resultNode);
          cy.zoom(1.1);
          cy.center(resultNode);
        });

        document.querySelector(".results").appendChild(tag);
      });
    }
  } else {
    cy.$("node").removeClass("not-hover");
  }
};

export const onZoom = (event) => {
  const cy = window.cy;

  if (event.target === cy) {
    const zoom = cy.zoom();
    const fixedZoom = zoom.toFixed(1);

    cy.$("node").forEach((el) => {
      const { size, color } = el.data();

      if (fixedZoom === "0.1")
        if (size <= 15) el.style("color", "rgba(0,0,0,0)");
      el.style("font-size", `${size - size * zoom}px`);
      if (fixedZoom === "0.3") {
        el.style({ "font-size": `${size - size * zoom}px`, color });
      }
      if (fixedZoom === "0.5") {
        el.style({ "font-size": `${size - size * zoom}px`, color });
      }
    });
  }
};

// Modals

export const skipIntro = () => {
  document.querySelector("#modal-intro").classList.add("none");
  document.querySelector("#filters-mobile").classList.remove("none");
  document.querySelector("#find").classList.remove("none");
  document.querySelector(".zoom").classList.remove("none");
};

// Filters
export const showFilters = () =>
  document.querySelector("#filters-mobile").classList.remove("hidden");
export const hideFilters = () =>
  document.querySelector("#filters-mobile").classList.add("hidden");

// About
export const showAboutModal = () => {
  document.querySelector("#modal-about").classList.add("show");
};

export const closeAboutModal = () => {
  document.querySelector("#modal-about").classList.remove("show");
};
