const getEdges = (xml) => xml.gexf.graph[0].edges[0].edge;
const getNodes = (xml) => xml.gexf.graph[0].nodes[0].node;
const getId = (el) => el.$.id;
const getPosition = (el) => el["viz:position"] ? ({
  x: parseFloat(el["viz:position"][0].$.x),
  y: parseFloat(el["viz:position"][0].$.y),
}) : undefined;
const getSize = (el) => el["viz:size"] ? parseFloat(el["viz:size"][0].$.value) : undefined;
const getValues = (el) => {
  let values = {};
  el.attvalues[0].attvalue.forEach((attr) => values[attr.$.for] = attr.$.value || '');
  return values;
}
const getData = (el) => {
  return {
    size: +getSize(el),
    name: `${getValues(el).fio2}\n${getValues(el)?.short_job?.length > 30 ? getValues(el).short_job.split('').slice(0, 40).join('') + '...' : getValues(el).short_job}`,
    fullName: `${getValues(el).fio2}\n${getValues(el)?.short_job}`,
    values: getValues(el)
  }
}

export const cssVar = (varName) => getComputedStyle(document.querySelector('html')).getPropertyValue(varName);

export function normalizeXML(xml) {
  const clusters = {
    0: 'A',
    2: 'B',
    4: 'C',
    5: 'D',
    6: 'E',
    7: 'F',
    8: 'G',
    13: 'H'
  };
  const cities = [
    'Москва',
    'Санкт-Петербург',
    'Казань',
    'Екатеринбург',
    'Самара',
    'Красноярск',
    'Барнаул',
    'Ижевск'
  ];

  const workTypes = [
    "ЗОЖ",
    "Партнерство",
    "Потребление",
    "Правосудие и мир",
    "Города",
    "Образование",
    "Инновации",
    "Культура"
  ]

  const nodes = getNodes(xml).map((el) => {
    const data = getData(el);
    // Цвет кластера
    const clusterLetter = clusters[data.values.modularity_class];
    const clusterColor = cssVar(`--cluster-${clusterLetter}-color`) || 'gray';
    // Цвет гендера
    let genderColor;
    if(data.values?.gender?.toLowerCase() === 'мужской')
      genderColor = cssVar(`--gender-0-color`);
    else if(data.values?.gender?.toLowerCase() === 'женский')
      genderColor = cssVar(`--gender-1-color`);
    else
      genderColor = cssVar(`--gender-neutral-color`);
    // Цвет региона
    let regionColor = cssVar(`--region-neutral-color`);
    const regionIndex = cities.map(el => el.toLowerCase()).indexOf(data.values?.region?.trim().toLowerCase());
    if(regionIndex !== -1) regionColor = cssVar(`--region-${regionIndex+1}-color`);

    // Тип работы
    let workIndex = workTypes.indexOf(data.values?.sdg_text_short_rus?.trim());
    if(workIndex === -1) workIndex = 'default';
    else workIndex += 1;
    const workColor = cssVar(`--work-${workIndex}-color`);

    return {
      data: {
        id: `${getId(el)}`,
        ...data,
        defaultSize: `${data.size * 2}px`,
        inDegreeSize: `${parseInt(data.values['in-degree']) * 5 + 10}px`,
        betweenessSize: `${parseFloat(data.values.betweenesscentrality) / 100 * 5 + 20}px`,
        clusterColor,
        genderColor,
        regionColor,
        clusterLetter,
        regionNumber: regionIndex !== -1 ? regionIndex + 1 : 999,
        workIndex: workIndex !== -1 ? workIndex : 'default',
        workColor
      },
      style: {
        "background-image": data.values.image_url?.replace('www.dropbox.com', 'dl.dropboxusercontent.com'),
        "background-fit": "cover cover",
        "background-image-opacity": 0.6,
        "font-size": `${data.size / 2}px`,
      },
      // classes: "multiline-auto",
      position: getPosition(el),
    }
  });

  const edges = getEdges(xml).map((el) => ({
    data: {source: el.$.source, target: el.$.target},
    style: {
      'line-color': nodes.find(n => n.data.id == el.$.source).data.color
    }
  }));

  // edges.filter((el) => el.source && el.target)
  // edges.forEach(el => console.log(el.id, el.source, el.target))

  // let b = [];
  // nodes.forEach(el => b.push(el.data.values.betweenesscentrality-0));
  // console.log(b, Math.min(...b), Math.max(...b))

  window.CLUSTER_PERCENT = {};
  Object.values(clusters).forEach(letter => {
    window.CLUSTER_PERCENT[letter] = `${(nodes.filter(n => n.data.clusterLetter === letter).length / nodes.length * 100).toFixed(1)}%`;
  })

  console.log(nodes[0])

  return { edges, nodes };
}

export const getOutgoingEdges = (node) => node.connectedEdges().filter(edge => edge.data().source === node.id());

export const hideNotBound = (node) => {
  const connectedEdges = node.connectedEdges();

  // Сначала все делаем прозрачными
  window.cy.$("node").addClass('not-hover');
  window.cy.$("edge").addClass('not-hover');


  node.removeClass('not-hover').addClass("hover");
  connectedEdges.removeClass('not-hover').addClass("hover");
  connectedEdges.addClass("hover");

  connectedEdges.forEach(el => {
    el.source().removeClass('not-hover').addClass('hover');
    el.target().removeClass('not-hover').addClass('hover');
  })
}

export const showAll = () => {
  window.cy.$("node").removeClass('not-hover').removeClass('hover');
  window.cy.$("edge").removeClass('not-hover').removeClass('hover');
}