import cytoscape from "cytoscape";
import inputGexf from "./sol3.gexf";
import { parseString } from "xml2js";
import { normalizeXML, getOutgoingEdges, showAll } from "./utils";
import style from "./style";

import {
  onCircleMouseOver,
  onCircleMouseLeave,
  onSizeChange,
  onColorChange,
  onZoom,
  onSearch,
  onCanvasClick,
  onNodeClick,
  onNodeMouseOut,
  onNodeMouseOver,
  skipIntro,
  showFilters,
  hideFilters,
  showAboutModal,
  closeAboutModal,
} from "./handlers";

import {closeModal} from './modal';

function init(data) {
  const config = {};

  config.container = document.getElementById("cy");
  config.boxSelectionEnabled = false;
  config.autounselectify = true;

  config.style = style;

  config.elements = {
    nodes: data.nodes,
    edges: data.edges,
  };

  config.layout = {
    name: "preset",
  };

  var cy = (window.cy = cytoscape(config));
  var bfs = cy.elements().bfs("#a", function () {}, true);

  // Desktop click
  cy.on("click", "node", onNodeClick(config));

  cy.elements("node").bind("mouseover", onNodeMouseOver);
  cy.elements("node").bind("mouseout", onNodeMouseOut);

  // Mobile tap
  cy.on("tap", "node", onNodeClick(config));

  // Desktop click
  cy.on("click", onCanvasClick);
  // Mobile tap
  cy.on("tap", onCanvasClick);

  // Prevent drag
  cy.nodes().ungrabify();

  // Очень сильно лагает
  // cy.on('zoom', onZoom(cy))

  document.querySelector(".zoom .zoom-in").addEventListener("click", () => {
    const zoom = cy.zoom() + 0.05;
    const pan = cy.pan();
    cy.zoom(zoom);
  });
  document.querySelector(".zoom .zoom-out").addEventListener("click", () => {
    const zoom = cy.zoom() - 0.05;
    const pan = cy.pan();
    cy.zoom(zoom);
  });

  // Search
  document
    .querySelector('input[name="search"]')
    .addEventListener("keyup", onSearch);
  // Mobile search
  if (window.innerWidth <= 600) {
    document
      .querySelector("#find .search-btn")
      .addEventListener("click", () => {
        onSearch();
        document
          .querySelector('input[name="search"]')
          .classList.remove("with-results");
      });
  }

  cy.center();

  let pan = cy.pan();
  cy.viewport({
    zoom: 0.25,
    pan: { ...pan, x: pan.x + 250 },
  });

  cy.on("zoom", () => {
    if (cy.zoom() <= 0.12)
      cy.viewport({
        zoom: 0.12,
        pan: cy.pan(),
      });
  });

  // Перекрашиваем ребра в цвета кластера (дефолтный фильтр цвета)
  window.cy
    .nodes()
    .forEach((node) =>
      getOutgoingEdges(node).forEach((edge) =>
        edge.style({
          "line-color": node.data().clusterColor,
          "target-arrow-color": node.data().clusterColor,
        })
      )
    );

  // Фильтр при загрузке - кластеры
  document.querySelectorAll(".colors .circle").forEach((el, i) => {
    const clusters = ["A", "B", "C", "D", "E", "F", "G", "H"];
    el.innerText = `${clusters[i]} ${CLUSTER_PERCENT[clusters[i]]}`;
    el.setAttribute("data-circle-value", clusters[i]);
  });
}

document.addEventListener("DOMContentLoaded", () => {

  fetch(inputGexf)
    .then((data) => data.text())
    .then((data) =>
      parseString(data, (err, result) => {
        init(normalizeXML(result));
      })
    );

  // Mobile
  if (window.innerWidth <= 600) {
    document
      .querySelector("#filters-mobile button.show-legend")
      .addEventListener("click", showFilters);
    document
      .querySelector("#filters-mobile button.hide-legend")
      .addEventListener("click", hideFilters);

    document.querySelector("#filters").remove();
  } else {
    document.querySelector(".zoom").classList.remove("none");

    document.querySelector("#filters-mobile").remove();
  }

  // Modals
  document
    .querySelector("#modal-intro button")
    .addEventListener("click", skipIntro);
  document
    .querySelector("#modal-info .close")
    .addEventListener("click", closeModal);
  document
    .querySelector("span.about")
    .addEventListener("click", showAboutModal);
  document
    .querySelector("#modal-about .close")
    .addEventListener("click", closeAboutModal);

  // Filters
  document
    .querySelector('select[name="filter-size"]')
    .addEventListener("change", onSizeChange);
  document
    .querySelector('select[name="filter-color"]')
    .addEventListener("change", onColorChange);

  document
    .querySelectorAll(".colors .circle")
    .forEach((el) => el.addEventListener("mouseover", onCircleMouseOver));
  document
    .querySelectorAll(".colors .circle")
    .forEach((el) => el.addEventListener("mouseleave", onCircleMouseLeave));

  document.querySelector('#modal-info .close').addEventListener('click', () => {
    showAll();
    window.INFO_OPENED = false;
  });
});
