const filterStyles = [
  {
    selector: "node.betweeness",
    style: {
      width: "data(betweenessSize)",
      height: "data(betweenessSize)"
    }
  },
  {
    selector: "node.in-degree",
    style: {
      width: "data(inDegreeSize)",
      height: "data(inDegreeSize)"
    }
  },

  {
    selector: "node.gender",
    style: {
      color: 'data(genderColor)',
      'background-color': 'data(genderColor)'
    }
  },
  {
    selector: "node.region",
    style: {
      color: 'data(regionColor)',
      'background-color': 'data(regionColor)'
    }
  },
  {
    selector: "node.work",
    style: {
      color: 'data(workColor)',
      'background-color': 'data(workColor)'
    }
  },
]

export default [
    {
      selector: "node",
      style: {
        content: "data(name)",
        "font-size": "data(size)",
        width: "data(defaultSize)",
        height: "data(defaultSize)",
        color: "data(clusterColor)",
        "background-color": "data(clusterColor)",
        "text-wrap": "wrap",
        "text-valign": "center",
        "text-halign": "right",
        "text-margin-x": "7px"
      },
    },
    ...filterStyles,
    {
      selector: "node.hover",
      style: {
        opacity: "1",
        content: "data(fullName)",
      },
    },
    {
      selector: "node.not-hover",
      style: {
        opacity: "0.2",
      },
    },
    {
      selector: "edge",
      style: {
        "curve-style": "unbundled-bezier",
        // "control-point-weights": '0.1 0.5 1 0.5 0.1',
        // "control-point-distances": "10 100 10",
        width: 1,
        opacity: "0.65",
        "line-color": "#ddd",
        // "target-arrow-color": "#ddd",
      },
    },
    {
      selector: "edge.hover",
      style: {
        opacity: "1",
        // "target-arrow-shape": "triangle",
      },
    },
    {
      selector: "edge.not-hover",
      style: {
        opacity: "0",
      },
    },
    {
      selector: ".highlighted",
      style: {
        "background-color": "#61bffc",
        "line-color": "#61bffc",
        "target-arrow-color": "#61bffc",
        "transition-property":
          "background-color, line-color, target-arrow-color",
        "transition-duration": "0.5s",
      },
    },
  ]